import React from "react"
import Layout from "../components/Layout"
import AboutHeader from "../components/AboutHeader"
import AboutMain from "../components/AboutMain"

const aboutus = () => {
  return (
    <Layout pageTitle="About Us">
      <AboutHeader />
      <AboutMain />
      <section className="content-section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12">
              <div className="section-title">
                <h2>
                Management
                  <br />
                </h2>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="director-team info-line-1">
               
                <div className="content">
                  <h6>Mr.Chris Chandiram</h6>

                  <p>Chief Executive Officer</p>
                  <p>
                    Chris Chandiram is an MBA graduate from the University of
                    Strathclyde in the UK with a professional background in
                    strategic management, key account management, and project
                    management in Sri Lanka and overseas.
                  </p>
                </div>
                <figure className="info-line-2">
                  <img src="/images/chris-chandiram.jpg" alt="Image" />
                </figure>
              </div>
            </div>

            <div className="section-title t1">
                <h2>
                Design & Engineering Team
                  <br />
                </h2>
              </div>

            <div className="col-lg-6">
              <div className="director-team info-line-1">
              <div className="content">
                  <h6>Mr. Cyrille Fernando</h6>
                  <p>Specialization: Architectural Design</p>
                  <p>Experience: 20+ years</p>
                  <p>
                    Overview: Mr.Fernando conceptualizes design with proven
                    construction management experience to deliver in the field
                    of high-end residences, hotels .
                  </p>
                </div>
                <figure className="info-line-2">
                  <img src="/images/images.jpg" alt="Image" />
                </figure>
                
              </div>
            </div>

            <div className="col-lg-6 ">
              <div className="director-team info-line-1">
              <div className="content">
                  <h6>Mr.RavindraMeewaddana</h6>
                  <p>Specialization: Structural Design</p>
                  <p>Experience: 35+ years</p>
                  <p>
                    Overview: A leader in Structural Engineering in Sri Lanka
                    with over 2500 projects successfully designed.
                  </p>
                </div>
                <figure className="info-line-2">
                  <img src="/images/images.jpg" alt="Image" />
                </figure>
               
              </div>
            </div>

            <div className="col-lg-6">
              <div className="director-team info-line-1">
              <div className="content">
                  <h6>Mr. Damian Fernando</h6>
                  <p>Specialization: Electrical Consultancy</p>
                  <p>Experience: 30+ years</p>
                  <p>
                    Overview: Has implemented and managed large power projects
                    in Sri Lanka
                  </p>
                </div>
                <figure className="info-line-2">
                  <img src="/images/images.jpg" alt="Image" />
                </figure>
              
              </div>
            </div>
            <div className="col-lg-6">
              <div className="director-team info-line-1">
              <div className="content">
                  <h6>Mr. Athula Senadeera</h6>
                  <p>Specialization: HVAC R</p>
                  <p>Experience: 32+ years</p>
                  <p>
                    Overview: Strong background in design and execution of large
                    HVAC & Refrigeration Projects
                  </p>
                </div>
                <figure className="info-line-2">
                  <img src="/images/images.jpg" alt="Image" />
                </figure>
                
              </div>
            </div>
            <div className="col-lg-6">
              <div className="director-team info-line-1">
              <div className="content">
                  <h6>Mr. Gamini Ranaweera</h6>
                  <p>Specialization: Fire & Plumbing</p>
                  <p>Experience: 30+ Years</p>
                  <p>
                    Overview: Proven background in Facility Management with
                    proven skills in MEP Design, execution & Maintenance
                  </p>
                </div>
                <figure className="info-line-2">
                  <img src="/images/images.jpg" alt="Image" />
                </figure>
               
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}
export default aboutus
